import {AfterViewInit, Component, ViewChild, ViewEncapsulation} from '@angular/core';
import {DataService} from "../../services/data.service";
import {
    Translatable,
    Culture,
    CategoryItemSelector,
    IWishList,
    ArticleBaseWithChildrenSelector
} from "../../interfaces/general";
import {LoginComponent} from "../login/login.component";
import {SettingsService} from "../../services/settings.service";
import {DigestService} from "../../services/digest.service";
import {NavigationStart, Router} from "@angular/router";
import {loadFromLocalStorage, loadFromSession} from "../../helpers/cookie.helper";
import {SharedAppSettings} from "../../shared-settings/shared-settings";
import {WishListService} from "../../services/wish-list-service";
import {CompareProductsService} from "../../services/compare-products.service";
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from "rxjs";
import {CartService} from "../cart/cart.service";
import {LuigiSuggestComponent} from "../luigi-suggest/luigi-suggest.component";
import {ArticleService} from "../articles/article.service";
import {MenuService} from "../../services/menu.service";

declare let $: any;
declare let moment: any;

@Component({
    selector: 'app',
    templateUrl: '../../tpl/app.html',
    styleUrls: ['../../assets/styles/layout.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AppComponent extends Translatable implements AfterViewInit {

    @ViewChild(LoginComponent) loginComponent: LoginComponent;
    @ViewChild(LuigiSuggestComponent) suggest: LuigiSuggestComponent;

    categories: CategoryItemSelector[];
    cultureOptions: Culture[];
    selectedCulture: Culture;
    currencyOptions: number[] = [3, 2, 1];

    tim: any;
    responseMenuDisplay: boolean = false;
    menuArticleCode: string;
    footerArticleCode: string;
    wishLists: IWishList[];
    cultureExpanded: boolean = false;
    currencyExpanded: boolean = false;
    ngUnsubscribe: Subject<any> = new Subject<any>();
    showUpperBar: boolean = true;

    selectedCurrencyId: number;

    footerRootArticle: ArticleBaseWithChildrenSelector;
    copy: string;

    get articles(): Array<ArticleBaseWithChildrenSelector> {
        if (!this.footerRootArticle || !this.footerRootArticle.childArticles || !this.footerRootArticle.childArticles.length) {
            return null;
        }

        return this.footerRootArticle.childArticles;
    }

    constructor(public dataSvc: DataService, public seSvc: SettingsService, public digestSvc: DigestService,
                private router: Router, private saSvc: SharedAppSettings, private wishListSvc: WishListService,
                public compareSvc: CompareProductsService, public cartSvc: CartService, private articleSvc: ArticleService, private menuSvc: MenuService) {
        super(dataSvc, seSvc);

        this.wishLists = wishListSvc.wishLists;
        this.wishListSvc.onWishListsChanged.subscribe((res: IWishList[]) => {
            this.wishLists = res;
        });

        this.menuArticleCode = this.saSvc.Defaults.MenuArticleCode;
        this.footerArticleCode = this.saSvc.Defaults.FooterArticleCode;


        this.cultureOptions = seSvc.settings.cultures;
        this.selectedCulture = this.seSvc.culture;


        this.selectedCurrencyId = this.seSvc.currencyId;

        this.router.events
            .pipe(
                filter((evt: any) => evt instanceof NavigationStart),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(res => {
                /**
                 * Ugly hack for not letting unauthorized users in on published DEV servers
                 */
                if (this.seSvc.settings.preAuth) {

                    if (res['url'].toLowerCase() != '/pa') {

                        let pa = this.seSvc.settings.preAuthToLocal ? loadFromLocalStorage('pa') : loadFromSession('pa');

                        let mt = moment();
                        let mtValidTo = pa ? moment(pa.validTo) : 0;

                        if (!pa || mt > mtValidTo) {
                            this.router.navigateByUrl('/pa').then(() => {
                            })
                        }
                    }
                }
                if (/registrace/.test(res['url']) && this.userLoggedIn) {
                    this.router.navigateByUrl('/').then(() => {
                    });
                }
                /**
                 * default title and description
                 */
                this.seSvc.settings.routesWithDefaultSeo.forEach(elem => {
                    if (elem.test(res['url'].toLowerCase())) {
                        this.setDefaultSeo();
                    }
                })
            });

        this.setCopy();
    }

    private setCopy(): void {
        let year = (new Date()).getFullYear().toString();
        this.copy = this.sen['app-copyright'].replace('yyyy', year);
    }

    setCulture(id: number): void {
        this.selectedCulture = this.cultureOptions.filter(c => c.cultureId == id)[0];
        this.seSvc.setCultureAndReload(true, id);
    }

    setCurrency(id: number): void {
        this.seSvc.setCultureAndReload(true, this.selectedCulture.cultureId, id);
    }


    ngOnInit(): void {
        this.getArticles();
    }

    getArticles(): void {
        this.articleSvc.getFooterArticles()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (res) => {
                    this.footerRootArticle = res;
                }
            );
    }

    ngAfterViewInit(): void {
        this.setDefaultSeo();
    }

    private setDefaultSeo(): void {
        let can = this.seSvc.settings.cultures.filter(f => f.cultureId === this.seSvc.culture.cultureId)[0].cultureBoundDomain;
        this.dataSvc.setPageHead(this.seSvc.settings.shopSeo.ShopDescription, can);
        this.dataSvc.setTitle(this.seSvc.settings.shopSeo.ShopTitle);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        clearTimeout(this.tim);
    }


    toggleCulture(): void {
        this.cultureExpanded = !this.cultureExpanded;
        if (this.cultureExpanded) {
            $('.js-culture').slideDown(250)
        } else {
            $('.js-culture').slideUp(250);
        }
    }

    toggleCurrency(): void {
        this.currencyExpanded = !this.currencyExpanded;
        if (this.currencyExpanded) {
            $('.js-currency').slideDown(250)
        } else {
            $('.js-currency').slideUp(250);
        }
    }

    onNoArticles(): void {
        this.showUpperBar = false;
    }


    toggleMenu(event:MouseEvent){
        this.menuSvc.toggleMenu.emit(event);
    }

}
