/**
 * @description
 * This service provides all common data fetching operations
 * Do not use for route specific fetching - e.g. product catalogue should fetch via it's own data service
 * to scale the file size of the JS
 */
import {EventEmitter, Injectable, Renderer2, RendererFactory2} from "@angular/core";
import {AuthInfo, BreadCrumbInfo} from "../interfaces/general";
import {Title, Meta} from "@angular/platform-browser";
import {SharedAppSettings} from "../shared-settings/shared-settings";
import {loadFromSession} from "../helpers/cookie.helper";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {SettingsService} from "./settings.service";

@Injectable()
export class DataService {
    private _userDisplayName: string;
    private pageTypeClassPrefix = 'page-type-';

    pageType: string = null;

    get userDisplayName(): string {
        return this._userDisplayName;
    }

    set userDisplayName(value: string) {
        this._userDisplayName = value;
    }

    private _categoryId: number;
    get categoryId(): number {
        return this._categoryId;
    }

    set categoryId(value: number) {
        this._categoryId = value;
    }

    private _dataLoading: number = 0;
    get dataLoading(): boolean {
        return this._dataLoading > 0;
    }

    set dataLoading(value: boolean) {
        if (value) {
            this._dataLoading++;
        } else {
            this._dataLoading--;
        }
        if (this._dataLoading < 0) {
            this._dataLoading = 0;
        }
    }

    private _mainMenuHidden: boolean;
    get mainMenuHidden(): boolean {
        return this._mainMenuHidden;
    }

    set mainMenuHidden(value: boolean) {
        this._mainMenuHidden = value;
    }

    private _bcHidden: boolean = true;
    get bcHidden(): boolean {
        return this._bcHidden;
    }

    set bcHidden(value: boolean) {
        this._bcHidden = value;
    }

    private _paHidden: boolean;
    get paHidden(): boolean {
        return this._paHidden;
    }

    set paHidden(value: boolean) {
        this._paHidden = value;
    }

    breadcrumbChanged: EventEmitter<BreadCrumbInfo[]>;
    private bc: BreadCrumbInfo[];
    domain: string;

    modalDialogSource: string;

    private renderer2: Renderer2;

    pageTypeChanged: EventEmitter<string> = new EventEmitter<string>();

    constructor(private seSvc: SettingsService, private sapSvc: SharedAppSettings, private titSvc: Title,
                private metaService: Meta, private router: Router, private rendererFactory2: RendererFactory2) {

        this.renderer2 = this.rendererFactory2.createRenderer(null, null);

        this.breadcrumbChanged = new EventEmitter();
        this.domain = this.sapSvc.Defaults.Domain;

        let ai = <AuthInfo>loadFromSession('ai');
        if (ai) {
            this.userDisplayName = ai.displayName;
        }

        /**
         * hiding main menu in cart
         */
        this.router.events.subscribe(res => {

            if (res instanceof NavigationEnd && res.urlAfterRedirects) {

                this.mainMenuHidden = /^\/kosik\//.test(res.urlAfterRedirects);
                this.bcHidden = /^\/$/.test(res.urlAfterRedirects);
                /**
                 * setting rel="canonical" - in case there is none in the data
                 * for given component
                 */
                let url = res.urlAfterRedirects;

                this.setBodyClass(res);
                this.pageTypeChanged.emit(this.pageType);


                document.querySelector('link[rel="canonical"]').setAttribute('href', `${this.domain}${url}`);
                /**
                 * removing <meta name="robots" content="noindex">
                 * this one is added by {@link ErrorComponent} to block indexing of the 404 page
                 * @type {HTMLMetaElement}
                 */
                let robotsElement: HTMLMetaElement = this.metaService.getTag(`name="robots"`);
                if (robotsElement) this.metaService.removeTagElement(robotsElement);
                /**
                 * setting and sending GA
                 */
                if (this.seSvc.gaSettings.analyticsId) {
                    (<any>window).gtag('config', this.seSvc.gaSettings.analyticsId, {
                        'page_path': res.urlAfterRedirects
                    });
                }
                if (this.seSvc.gaSettings.remarkId) {
                    (<any>window).gtag('event', 'page_view', {
                        'send_to': this.seSvc.gaSettings.remarkId,
                        'items': [{
                            'google_business_vertical': 'retail'
                        }]
                    });

                }
            }

            if (res instanceof NavigationStart) {
                /**
                 * used for hiding/displaying elements on /pa route
                 * this is needed cos of z-index hierarchy on app.html
                 */
                this.paHidden = /^\/pa$/.test(res.url);
            }
        });
    }

    private setBodyClass(routerEvent: any): void {
        if (!routerEvent || !routerEvent.urlAfterRedirects) {
            return;
        }

        let splitted = routerEvent.urlAfterRedirects.split('/');
        if (!splitted || !splitted.length || splitted.length < 2) {
            return;
        }

        let pageType = splitted[1];
        if (!pageType) {
            pageType = 'index';
        }

        this.pageType = pageType;

        pageType = `${this.pageTypeClassPrefix}${pageType}`;

        document.body.classList.forEach((cls) => {
            if (cls.indexOf(this.pageTypeClassPrefix) == 0) {
                this.renderer2.removeClass(document.body, cls);
            }
        });


        this.renderer2.addClass(document.body, pageType);


    }

    setBc(bcInfo: BreadCrumbInfo[], seoTitle?: string): void {
        this.bc = bcInfo;
        if (bcInfo && bcInfo.length > 0) {
            let title = seoTitle || `${this.seSvc.settings.shopSeo.ShopTitle} - ${bcInfo[bcInfo.length - 1].displayName}`;
            this.setTitle(title);
        }

        this.bcHidden = !(this.bc && this.bc.length);
        this.breadcrumbChanged.emit(this.bc);
    }

    setPageHead(description: string, seoUrl: string, image?: string): void {
        /**
         * setting rel="canonical"
         * with the one retrieved from entity data in component
         */
        document.querySelector('link[rel="canonical"]').setAttribute('href', `${seoUrl}`);
        /**
         * setting <meta name="description" content="something">
         */
        const metaDesc = description || '';
        this.metaService.updateTag({name: 'description', content: metaDesc});

        /* fb meta tags */
        this.metaService.updateTag({property: 'og:description', content: metaDesc});
        this.metaService.updateTag({property: 'og:url', content: seoUrl});
        this.metaService.updateTag({property: 'og:image', content: image || ''});
    }

    setTitle(text: string): void {
        this.titSvc.setTitle(text);
        this.metaService.updateTag({property: 'og:title', content: text});
    }

    setNoIndex(): void {
        this.metaService.addTag({name: 'robots', content: 'noindex'});
    }

    appendLinkedData(data: any, elId: string = 'mainEntityData'): void {
        let head = document.querySelector('head');
        let scriptElement: HTMLScriptElement = <HTMLScriptElement>document.querySelector(`script[type="application/ld+json"][id="${elId}"]`);
        if (!scriptElement) {
            scriptElement = document.createElement('script');
            scriptElement.type = 'application/ld+json';
            scriptElement.id = elId;
            scriptElement.text = JSON.stringify(data);
            head.appendChild(scriptElement);
        } else {
            scriptElement.text = JSON.stringify(data);
        }
    }

}

