import {Injectable} from "@angular/core";
import {loadFromLocalStorage, removeFromLocalStorage, saveToLocalStorage} from "../helpers/cookie.helper";
import {AuthInfo} from "../interfaces/general";

declare let StringView: any;
declare let moment: any;

@Injectable()
export class CredentialStorage {

    static get userLoggedIn(): boolean {
        return CredentialStorage.isAiTimeValid();
    }

    static get authInfo(): AuthInfo {
        let b64Ai: AuthInfo = loadFromLocalStorage('ai');
        if (!b64Ai) return null;

        let ais = JSON.parse(StringView.makeFromBase64(b64Ai));
        if (ais) {
            return ais;
        } else return null;
    }

    static set authInfo(value: AuthInfo) {
        let b64Ai = new StringView(JSON.stringify(value)).toBase64();
        saveToLocalStorage('ai', b64Ai);
    }

    constructor () { }

    static removeAuthInfo() {
        removeFromLocalStorage('ai');
    }

    static getStoredJwTokenBody(): string {

        let ai: AuthInfo = CredentialStorage.authInfo;

        if (!ai || !ai.jwTokenBody) {
            return undefined;
        }
        return `Bearer ${ai.jwTokenBody}`;
    }

    static isAiTimeValid(): boolean {
        let mt = moment();
        let ai: AuthInfo = CredentialStorage.authInfo;
        if (!ai) return false;

        let mtValidTo = moment(ai.validTo);
        return mtValidTo > mt;
    }
}