/**
 * Component displaying breadcrumbs.
 */
import {Component, Input, OnDestroy, ViewEncapsulation} from '@angular/core';
import {DataService} from "../../services/data.service";
import {Subject} from "rxjs";
import {BreadCrumbInfo} from "../../interfaces/general";
import {takeUntil} from "rxjs/operators";

interface BcInfoInternal extends BreadCrumbInfo {
    link: any[];
}

@Component({
    selector: 'cmp-breadcrumb',
    templateUrl: '../../tpl/breadcrumb.html',
    styleUrls: ['../../assets/styles/2-components/breadcrumb.scss'],
    encapsulation: ViewEncapsulation.None
})

export class BreadcrumbComponent implements OnDestroy {

    @Input() homePrefix: string;
    bcInfo: BreadCrumbInfo[];
    bcInfoInternal: BcInfoInternal[];
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(dataSvc: DataService) {
        dataSvc.breadcrumbChanged
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(res => {

                if(!res){
                    this.bcInfo = null;
                    this.bcInfoInternal=null;
                    return;
                }

                let home: BreadCrumbInfo = {displayName: this.homePrefix, targetSeoTail: ''};
                this.bcInfo = [home, ...res];

                this.bcInfoInternal = [];
                this.bcInfo.forEach((elem) => {
                    let link: any[];
                    let slash: string;
                    if (!elem.targetSeoTail) {
                        slash = '/';
                    }
                    else {
                        slash = elem.targetSeoTail[0] === '/' ? '' : '/';
                    }

                    if (elem.entityPrefix) {
                        link = [`/${elem.entityPrefix}/${elem.targetId}${slash}${elem.targetSeoTail}`]
                    } else {

                        link = [`${slash}${elem.targetSeoTail}`];
                    }

                    let newElem: BcInfoInternal = {
                        entityPrefix: elem.entityPrefix,
                        displayName: elem.displayName,
                        targetId: elem.targetId,
                        targetSeoTail: elem.targetSeoTail,
                        link: link
                    };
                    this.bcInfoInternal.push(newElem)
                });
            })
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
