import {Component, ViewEncapsulation} from "@angular/core";
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

export type RegistrationResult = 'Success' | 'AlreadyRegistered' | 'Failure' | 'None';

export interface RegistrationStatus {
    status: RegistrationResult;
}

@Component({
    selector: 'cmp-newsletter-registration',
    templateUrl: '../../tpl/newsletter-registration.html',
    styleUrls: ['../../assets/styles/2-components/newsletter.scss'],
    encapsulation: ViewEncapsulation.None
})

export class NewsLetterRegistrationComponent extends Translatable {

    regStatus: RegistrationStatus = {status: 'None'};
    statusMsg: string;
    newsForm: FormGroup;
    isFailure: boolean = false;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, public seSvc: SettingsService, private http: HttpClient,
                private fb: FormBuilder) {
        super(dataSvc, seSvc);

        let patEmail = this.seSvc.settings.validationPatterns.email;

        this.newsForm = this.fb.group({
            email: ['', [Validators.required, Validators.pattern(patEmail)]],
            gdprNews: [false, [Validators.requiredTrue]]
        })
    }

    register() {
        this.isFailure = false;
        if (!this.newsForm.valid) return;

        let email: string = this.newsForm.get('email').value;

        this.http.post<RegistrationStatus>(`api/newsletter/register`, {email: email})
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                let signal: boolean = false;
                this.regStatus = res;
                switch (this.regStatus.status) {
                    case 'Success':
                        this.statusMsg = this.sen['newsletter-reg-success'];
                        signal = true;
                        this.newsForm.reset();
                        break;
                    case 'AlreadyRegistered':
                        this.statusMsg = this.sen['newsletter-reg-already-reg'];
                        this.isFailure = true;
                        signal = true;
                        break;
                    case 'Failure':
                        this.statusMsg = this.sen['newsletter-reg-failure'];
                        this.isFailure = true;
                        signal = true;
                        break;
                }
                if (signal) {
                    let tim: any = setTimeout(() => {
                        this.regStatus.status = 'None';
                        clearTimeout(tim);
                    }, 3500)
                }
            });

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    inputKeyUp(evt: KeyboardEvent): void {
        if (evt.key === 'Enter' || evt.key == 'NumpadEnter') {
            this.register();
        }
    }
}