import {ModuleWithProviders}  from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {RouteGuardService} from "../../services/route-guard.service";

/**
 * IMPORTANT:
 * Do NOT forget to add a RegEx for the route to "FlexMvc/Helpers/RouteEval.Helper.cs"
 * Do NOT forget to test it!!!
 */
export const routes: Routes = [
    {path: '', loadChildren: '../index/index.module#IndexModule', pathMatch: 'full'},
    {path: 'pa', loadChildren: '../pre-auth/pre-auth.module#PreAuthModule'},
    {path: 'c', loadChildren: '../category/category.module#CategoryModule'},
    {path: 'v', loadChildren: '../product/product.module#ProductModule'},//specific variant
    {path: 'p', loadChildren: '../producers/producers.module#ProducersModule'},
    {path: 'registrace', loadChildren: '../register-b2c/register-b2c.module#RegisterB2CModule'},
    {path: 'clanek', loadChildren: '../articles/article-detail.module#ArticleDetailModule'},
    {path: 'upozorneni', loadChildren: '../warnings/warnings.module#WarningsModule'},
    {path: 'muj-ucet', loadChildren: '../account/account.module#AccountModule', canActivate: [RouteGuardService]},
    {path: 'kosik', loadChildren: '../cart/cart.module#CartModule'},
    {path: 'zapomenute-heslo', loadChildren: '../forgotten-password/forgotten-password.module#ForgottenPasswordModule'},
    {path: 'vysledky-vyhledavani', loadChildren: '../search-result/search-result.module#SearchResultModule'},
    //{path: 'vysledky-vyhledavani', loadChildren: 'app/modules/luigi-search/luigi-search.module#LuigiSearchModule'},
    {path: 'novinky-potvrzeni', loadChildren: '../newsletters/newsletter-confirmation.module#NewsLetterConfirmationModule'},
    {path: 'novinky-zruseni', loadChildren: '../newsletters/newsletter-unregistration.module#NewsLetterUnregistrationModule'},
    {path: 'chyba', loadChildren: '../warnings/warnings.module#WarningsModule'},
    // preparing GP WebPay routes
    // {path: 'gpwebpay', loadChildren: 'app/modules/cart/cart.module#CartModule'},
    {path: 'porovnat', loadChildren: '../compare/compare.module#CompareModule'},
    // DO NOT ADD THIS TO "FlexMvc/Helpers/RouteEval.Helper.cs"
    {path: '**', redirectTo: '/chyba/404'}
];

export const appRoutingProviders: any[] = [
    RouteGuardService
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);
