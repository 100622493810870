import {EventEmitter, Injectable} from "@angular/core";
import {isRouteSecured} from '../helpers/string.helper';
import {AuthInfo, CartAdjustAction, LoginEmitterMessage, LoginResult} from "../interfaces/general";
import {SettingsService} from "./settings.service";
import {ICartStatus} from "../modules/cart/common";
import {Router} from "@angular/router";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {CredentialStorage} from "./credential-storage.service";
import {CartService} from "../modules/cart/cart.service";
import {loadFromSession, removeFromSession, saveToSession} from "../helpers/cookie.helper";

declare let moment: any;

// todo: UNIT TEST FOR THIS SERVICE

@Injectable()
export class DigestService {

    badCredentialsMessage: string = 'bad credentials, login failed';
    errorType:string = 'error';

    userName: string;
    password: string;
    requestedUri: string;
    requestedMethod: string;

    loginStatus: EventEmitter<LoginEmitterMessage>;
    onLoginQuery: EventEmitter<any> = new EventEmitter<any>();

    constructor(private http: HttpClient, private seSvc: SettingsService,
                private router: Router, private  cartSvc: CartService) {

        let aiValid: boolean = false;

        const ai: AuthInfo = CredentialStorage.authInfo;
        if (ai) {
            const mt = moment();
            const mtValidTo = moment(ai.validTo);
            if (mtValidTo > mt) {
                aiValid = true;
            }
        }

        if (!aiValid) {
            /**
             * This means fresh start, nothing in session/local, we settle new values
             */
            DigestService.initNotSigned();
        } else {
            /**
             * this means there was full page reload, so we rather take stored values
             * */
            this.userName = ai.userName;
        }

        this.loginStatus = new EventEmitter();
    }

    private static initNotSigned(): void {
        CredentialStorage.removeAuthInfo();
    }

    // BLOCK ADJUST CART

    private getCartStatus(): any {
        let url = `api/cart/getCartStatus`;
        return this.http.get(url)
    }

    private adjustCart(action: CartAdjustAction): void {
        let url = `api/cart/cartAdjust/${action ? action : ''}`;
        this.http.get(url)
            .subscribe(() => {
                // cart adjusted saved to session not to repeat it in the same session
                saveToSession('cadj', true);
                location.reload();
            });
    }

    // BLOCK ADJUST CART

    login(userName: string, pwd: string): void {

        this.requestedUri = 'api/login';
        this.requestedMethod = 'GET';

        this.userName = userName;
        this.password = pwd;
        let headers: HttpHeaders = this.getLoginHeaders();

        this.http.get<LoginResult>(this.requestedUri, {headers: headers})
            .subscribe(
                (r) => {
                    CredentialStorage.authInfo = {
                        jwTokenBody: r.jwTokenBody,
                        userName: this.userName,
                        displayName: r.companyDisplayName,
                        loggedIn: true,
                        validTo: moment().add(r.tokenExpirationMinutes, 'minutes').format()
                    };

                    this.seSvc.setCulture(r.cultureId, r.currencyId);

                    /**
                     * remove this block (BLOCK ADJUST CART) and all concerned functions
                     * if you don't want to use cart adjustment after login
                     * place just single location.reload(); in place of this code
                     */
                        // BLOCK ADJUST CART

                    const cartAlreadyAdjusted = <boolean>loadFromSession('cadj');
                    if (!cartAlreadyAdjusted) {
                        this.getCartStatus()
                            .subscribe((res: ICartStatus) => {
                                this.cartSvc.cartTokenSvc.saveCartToken(res.cartToken);
                                if (res.NotLoggedProductsCnt > 0 && res.PrevLoggedProductsCnt > 0 && this.seSvc.settings.cartAdjustAction == 'ask') {
                                    this.adjustCart('merge');
                                } else {
                                    this.adjustCart(null);
                                }
                            });
                    } else {
                        location.reload();
                    }

                    // BLOCK ADJUST CART
                },
                (e: HttpErrorResponse) => this.loginStatus.emit({
                    type: this.errorType,
                    message: this.badCredentialsMessage,
                    data: e
                })
            );
    }

    logOut(routeToIndex?: boolean) {

        CredentialStorage.removeAuthInfo();
        this.userName = undefined;
        this.password = undefined;
        removeFromSession('cadj');

        this.cartSvc.cleanCartOnLogout();

        let fwd = this.router.url;
        let url;
        if (routeToIndex || isRouteSecured(fwd)) {
            if (routeToIndex) {
                url = '/';
            } else {
                url = fwd ? `/upozorneni?fwd=${fwd}` : '/upozorneni';
            }
            this.router.navigateByUrl(url).then(() => {
                location.reload();
            });
        } else {
            location.reload();
        }
    }

    queryLogin(): void {
        this.onLoginQuery.emit();
    }

    private getLoginHeaders(): HttpHeaders {
        let result = `Basic username="${this.userName}", password="${this.password}"`;

        return new HttpHeaders({'Authorization': result});
    }
}
